import {
  Button,
  Image,
  Pagination,
  Popconfirm,
  Select,
  Table,
  Tag,
} from "antd";
import { getAllDocs, getDoctype, updateDocs } from "./KycFun";
import { useEffect, useState } from "react";

const Kyc = () => {
  const [kycdata, setKycData] = useState([]);
  const [docType, setDocType] = useState([]);
  const [action, setAction] = useState("");
  const [totalPage, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [verifiedDoc, setVerifiedDoc] = useState("");
  const [documentID, setDocumentID] = useState();

  const handleChangeStatus = (type, id) => {
    const payload = { isVerified: type === "approved" ? "1" : "2" };
    updateDocs(payload, `${id}`)
      .then((res) => {
        setAction((prevAction) => ({
          ...prevAction,
          [id]: type === "approved" ? "1" : "2",
        }));
        getAllKycs(currentPage);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const setwithdrawAction = (id, value) => {
    console.log(id, value);
    setAction((prevAction) => ({
      ...prevAction,
      [id]: value,
    }));
  };
  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      render: (data, record) => {
        return (
          <>
            <Tag className=" bg-rgbaprimary1 border-rgbaprimary3" title={data}>
              {data ?? "--"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Vendor Email",
      dataIndex: "vendorEmail",
      key: "vendorEmail",
      render: (data, record) => {
        return (
          <>
            <Tag
              className=" bg-rgbaprimary1 border-rgbaprimary3"
              title={record.shop_name}
            >
              {data ?? "--"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Vendor Mobile",
      dataIndex: "vendorMobile",
      key: "vendorMobile",
      render: (data, record) => {
        return (
          <>
            <Tag
              className=" bg-rgbaprimary1 border-rgbaprimary3"
              title={record.shop_name}
            >
              {data ?? "--"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Document Name",
      dataIndex: "documentName",
      key: "documentName",
      render: (data, record) => {
        return (
          <>
            <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
              {data ?? "--"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Document Details",
      dataIndex: "document_details",
      key: "document_details",
      render: (data, record) => {
        return (
          <>
            <Tag className=" bg-rgbaprimary1 border-rgbaprimary3">
              {data ?? "--"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Document Images",
      dataIndex: "document_details",
      key: "document_details",
      render: (data, record) => {
        console.log({ record });
        return (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 place-items-center gap-4 w-full">
              {record?.document_images ? (
                record?.document_images.map((i) => {
                  return (
                    <Image
                      preview
                      src={`${process.env.REACT_APP_IMAGE_URL}${i}`}
                      width={30}
                      height={30}
                      alt="doc"
                      className="w-10"
                    />
                  );
                })
              ) : (
                <p className="text-xs">No Image</p>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "is_verified",
      key: "is_verified",
      className: "custom-header font-poppins text-xs",
      render: (data, record) => {
        return (
          <div>
            <Select
              disabled={
                record.is_verified === "1" || record.is_verified === "2"
              }
              placeholder={
                record.is_verified === "2"
                  ? "Rejected"
                  : record.is_verified === "1"
                  ? "Approved"
                  : "Select "
              }
              style={{
                width: 120,
              }}
              onChange={(value) => setwithdrawAction(record.id, value)}
              value={action[record.id]}
            >
              <Select.Option value="1">Approved</Select.Option>
              <Select.Option value="2">Rejected</Select.Option>
            </Select>
            {action[record.id] === "1" && (
              <Popconfirm
                title={
                  <div>
                    <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                      Are you sure to Approve this KYC request?
                    </p>
                  </div>
                }
                onConfirm={() => handleChangeStatus("approved", record.id)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button type="link">Submit</Button>
              </Popconfirm>
            )}
            {action[record.id] === "2" && (
              <Popconfirm
                title={
                  <div>
                    <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                      Are you sure to Reject this KYC request?
                    </p>
                  </div>
                }
                onConfirm={() => handleChangeStatus("rejected", record.id)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button type="link" size="small">
                  Submit
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];
  const getAllKycs = () => {
    getAllDocs(
      `?documentId=${documentID}&isVerified=${verifiedDoc}&page=${currentPage}&limit=10`
    )
      .then((res) => {
        if (res.data.status === "success") {
          setKycData(res.data.data.kycDetails);
          setCurrentPage(res.data.data.pagination.currentPage);
          setTotalPages(res.data.data.pagination.totalPages);
        }
      })
      .catch((err) => console.log(err));
  };
  const getFilteredKycs = (e) => {
    console.log(e);
    getDoctype(`?documentName=${e}`)
      .then((res) => {
        if (res.data.status === "success") {
          setDocType(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllKycs();
    getFilteredKycs();
  }, [currentPage, verifiedDoc, documentID]);
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div className="flex items-end justify-between  m-4">
        <Select
          placeholder="Select Document Type"
          options={docType?.map((i) => ({
            value: i.document_type,
            label:
              i.document_name === "aadhar_card"
                ? "Aadhar Card"
                : i.document_name === "pan_card"
                ? "PAN Card"
                : "Pharmacy Document",
          }))}
          onChange={(e) => setDocumentID(e)}
          style={{ width: "25%" }}
        />
        <Select
          placeholder="Select Verification Type"
          options={[
            { value: 1, label: "Approved" },
            { value: 2, label: "Rejected" },
            { value: 0, label: "Pending" },
          ]}
          onChange={(e) => setVerifiedDoc(e)}
          style={{ width: "25%" }}
        />
      </div>

      <div
        style={{ maxHeight: "500px", overflow: "auto" }}
        className="bg-white"
      >
        <Table
          title={() => (
            <p className="font-medium text-xl p-5 text-primary">Kyc Details</p>
          )}
          bordered={true}
          columns={columns}
          dataSource={kycdata}
          pagination={false}
          className="w-screen sm:w-screen"
          size="small"
        />
      </div>
      <Pagination
        className="mt-2  w-full "
        onChange={handlePageChange}
        current={currentPage}
        defaultCurrent={1}
        total={10 * totalPage}
        showSizeChanger={false}
      />
    </>
  );
};

export default Kyc;
