import { Get, getAPIUrl, Post, Put } from "../../../services/apiMethod";

const kycEndpoint = {
  getdocs: "/admin/kyc/documents",
  updateDocs: "/admin/kyc/update-document-status/",
  getDocType: "/vendor/kyc/docs",
};

export const getAllDocs = (param = "") => {
  const url = getAPIUrl(kycEndpoint.getdocs, param);
  return Get(url);
};

export const updateDocs = (data, param = "") => {
  const url = getAPIUrl(kycEndpoint.updateDocs, param);
  return Put(url, data);
};
export const getDoctype = (param = "") => {
  const url = getAPIUrl(kycEndpoint.getDocType, param);
  return Get(url);
};
