import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setLocalStorageItem } from "../../../../src/utils/index";
import { Button, Input, Form, message } from "antd";
import { images } from "../../../assets/images";
import { loginApi, OtpVerify } from "./AuthapiFunctions";

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [verifyToken, setverifyToken] = useState(false);
  const [form] = Form.useForm();

  const handleLogin = (values) => {
    setLoading(true);
    const data = {
      mobile_number: values.mobile_number,
      password: values.password,
    };

    loginApi(data)
      .then((response) => {
        if (response.data.status === "success") {
          setShowOtp(true);
          setverifyToken(response.data.data?.verification_token);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleLoginOtpVerify = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("mobile_number", values.mobile_number);
    formData.append("otp", parseInt(values.otp));
    formData.append("verificationToken", verifyToken);
    OtpVerify(formData)
      .then((res) => {
        if (res.data.status === "success") {
          setLocalStorageItem("isLoggedIn", true);
          setLocalStorageItem("access_token", res.data.data?.access_token);
          setLocalStorageItem("userDetails", res.data.data?.user);
          setShowOtp(false);
          navigate("/dashboard");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      form.submit(); // Submit the form when Enter key is pressed
    }
  };

  return (
    <div className="h-full w-full bg-gradient-to-r from-rgbaprimary1 via-rgbaprimary3 to-rgbaprimary9">
      <div className="flex items-center justify-center h-screen flex-col rounded-sm">
        <div className="p-7 w-72 md:w-1/2 lg:w-1/4 rounded-md bg-blue-50 h-auto">
          <div>
            <img
              src={images.Logo}
              alt="logo"
              className="w-auto mt-3 mb-3 mx-auto h-40 flex justify-center items-center"
            />
          </div>

          <p className="text-center text-2xl block text-rgbaprimary9 font-medium mb-6">
            Admin Panel
          </p>

          <Form
            form={form}
            layout="vertical"
            onFinish={showOtp ? handleLoginOtpVerify : handleLogin}
            onKeyDown={handleKeyPress}
          >
            <Form.Item
              label={
                <p className="block text-rgbaprimary9 text-md font-medium ">
                  Mobile Number
                </p>
              }
              name="mobile_number"
              rules={[
                { required: true, message: "Please enter your mobile number!" },
              ]}
            >
              <Input
                placeholder="Enter mobile number here..."
                className="shadow appearance-none border rounded w-full py-2 px-3 text-rgbaprimary9 text-sm leading-tight focus:outline-none focus:shadow-outline"
              />
            </Form.Item>

            {showOtp ? (
              <Form.Item
              label={
                <p className="block text-rgbaprimary9 text-md font-medium">
              OTP
                </p>
              }
                name="otp"
                rules={[{ required: true, message: "Please enter the OTP!" }]}
              >
                <Input.OTP
                  placeholder="Enter OTP here..."
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-rgbaprimary9 text-sm leading-tight focus:outline-none focus:shadow-outline"
                />
              </Form.Item>
            ) : (
              <Form.Item
                label={
                  <p className="block text-rgbaprimary9 text-md font-medium">
                    Password
                  </p>
                }
                name="password"
                rules={[
                  { required: true, message: "Please enter your password!" },
                ]}
              >
                <Input.Password
                  placeholder="Enter password here..."
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-rgbaprimary9 text-sm leading-tight focus:outline-none focus:shadow-outline"
                />
              </Form.Item>
            )}

            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="text-white h-10 bg-gradient-to-tr from-rgbaprimary6 to-rgbaprimary9 w-full font-semibold"
            >
              {showOtp ? "Verify OTP" : "Login"}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
