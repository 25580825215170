import React, { lazy } from "react";
import PrivateAuth from "./PrivateAuth";
import Customers from "../Views/Main Panel/Customers";
import Reports from "../Views/Main Panel/Reports/Reports";
import Kyc from "../Views/Main Panel/KYC/Kyc";
const Shops = lazy(() => import("../Views/Main Panel/Reports/Shops"));

const Dashboard = lazy(() => import("../Views/Main Panel/Dashboard"));
const InventoryRequests = lazy(() =>
  import("../Views/Main Panel/InventoryRequests")
);
const CategoryDetails = lazy(() =>
  import("../Views/Main Panel/CategoryDetails")
);
const VendorsDetails = lazy(() => import("../Views/Main Panel/VendorsDetails"));

const privateAdminRoute = () => {
  return [
    {
      element: <PrivateAuth />,
      children: [
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/vendorsDetails",
          element: <VendorsDetails />,
        },
        {
          path: "/inventoryRequests",
          element: <InventoryRequests />,
        },
        {
          path: "/categoryDetails",
          element: <CategoryDetails />,
        },
        {
          path: "/showShops",
          element: <Shops />,
        },
        {
          path: "/showCustomers",
          element: <Customers />,
        },
        {
          path: "/salesReports",
          element: <Reports />,
        },
        {
          path: "/kyc-report",
          element: <Kyc />,
        },
      ],
    },
  ];
};

export default privateAdminRoute;
